import { StringMap } from '@naturalcycles/js-lib'
import { LANG } from '@naturalcycles/shared'
import enCA from '../../locales/en-CA.json'
import enUS from '../../locales/en-US.json'
import esUS from '../../locales/es-US.json'
import ptBR from '../../locales/pt-BR.json'
import svSE from '../../locales/sv-SE.json'

export enum SupportedLanguage {
  en = 'en',
  es = 'es',
  pt = 'pt',
  sv = 'sv',
}

export enum SupportedLocaleSuffix {
  US = 'US',
  BR = 'BR',
  SE = 'SE',
  CA = 'CA',
}

export enum SupportedLocale {
  enUS = 'en-US',
  esUS = 'es-US',
  ptBR = 'pt-BR',
  svSE = 'sv-SE',
  enCA = 'en-CA',
}

/**
 * SiteLocale is a DatoCMS enum for locales.
 * Used for migrated templates.
 */
export enum SiteLocale {
  enUS = 'en_US',
  esUS = 'es_US',
  ptBR = 'pt_BR',
  svSE = 'sv_SE',
  enCA = 'en_CA',
}

export const supportedLocaleToLANG: Record<SupportedLocale, LANG> = {
  [SupportedLocale.enUS]: LANG.en_US,
  [SupportedLocale.esUS]: LANG.es_US,
  [SupportedLocale.ptBR]: LANG.pt_BR,
  [SupportedLocale.svSE]: LANG.sv_SE,
  [SupportedLocale.enCA]: LANG.en_CA,
}

export enum SupportedUrl {
  enUS = '',
  esUS = 'es',
  ptBR = 'pt-br',
  svSE = 'sv',
  enCA = 'ca',
}

// This allows fallbackLocale functionality for translations
function getOverwrittenTranslation(original: StringMap, overwrite: StringMap): StringMap {
  const result = { ...original }
  for (const key in overwrite) {
    if (overwrite[key]) result[key] = overwrite[key]!
  }
  return result
}

export const translations: Record<SupportedLocale, StringMap> = {
  'en-US': enUS,
  'es-US': esUS,
  'pt-BR': ptBR,
  'sv-SE': svSE,
  // if we haven't specified a translation for a key in en-CA, we'll use the one from en-US
  'en-CA': getOverwrittenTranslation(enUS, enCA),
}
