import { _stringEnumKey, isClientSide, isServerSide } from '@naturalcycles/js-lib'
import { BurgerIcon } from '@src/components/burger-icon/BurgerIcon.component'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { DesktopMenu } from '@src/components/desktop-menu/DesktopMenu.component'
import { HeaderThemes } from '@src/components/header/Header.component'
import { Logo } from '@src/components/logo/Logo.component'
import { RegionIndicator } from '@src/components/region-indicator/RegionIndicator.component'
import { RegionPicker } from '@src/components/region-picker/RegionPicker.component'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { RegionSlug, ShippingLocation, shippingRegionPages } from '@src/shop/cnst/shopify.cnst'
import { CartIcon as ShopifyCartIcon } from '@src/shop/components/cart-icon/CartIcon.component'
import { ShopifyProduct } from '@src/shop/store/shopify.model'
import {
  selectExpectedLocation,
  selectShopifyShippingLocation,
  setExpectedLocationByCountry,
  setShippingLocation,
  setShippingLocationByCountry,
} from '@src/shop/store/shopify.slice'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styles from './HeaderShop.module.scss'

interface HeaderShopProps {
  products: ShopifyProduct[]
}

export function HeaderShop({ products }: HeaderShopProps): ReactNode {
  const isMobile = useBreakpoint().width < Breakpoints.bpLarge
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const country = useAppSelector(selectGeolocationCountry)

  function urlFragmentToShippingLocation(url: string): ShippingLocation | null {
    const parsed = url.split('?')[0]!
    const parts = parsed.split('/')
    const shopIndex = parts.indexOf('shop')
    const locationIndex = shopIndex + 1
    const urlLocationPart = parts[locationIndex]
    return shippingRegionPages.find(page => page.slug === urlLocationPart)?.shopifyTag || null
  }

  const dispatch = useAppDispatch()

  function getHomeUrl(shippingLocation?: ShippingLocation): string {
    if (isServerSide()) return '/shop/row' // We can't get the window object while SSGing
    if (!shippingLocation) return `/shop/row${window.location.search}`
    const regionKeyOrBlocked = _stringEnumKey(ShippingLocation, shippingLocation)
    const slugSuffix = regionKeyOrBlocked === 'Blocked' ? 'row' : RegionSlug[regionKeyOrBlocked]
    return `/shop/${slugSuffix}${window.location.search}`
  }

  const [shopHomeUrl, setShopHomeUrl] = useState(getHomeUrl(shippingLocation))

  function selectRegion(shippingRegion?: ShippingLocation): void {
    if (!shippingRegion) {
      dispatch(setShippingLocationByCountry(country))
    } else {
      dispatch(setShippingLocation(shippingRegion))
    }
  }

  const expectedLocation = useAppSelector(selectExpectedLocation)

  const [showRegionIndicator, setShowRegionIndicator] = useState(false)

  const urlLocation = isClientSide()
    ? urlFragmentToShippingLocation(window.location.pathname)
    : null
  const useRegion = urlLocation || shippingLocation

  useEffect(() => {
    if (!expectedLocation) {
      dispatch(setExpectedLocationByCountry(country))
    }
  }, [country, expectedLocation])

  useEffect(() => {
    setShowRegionIndicator(useRegion !== expectedLocation)
  }, [useRegion, expectedLocation])

  useEffect(() => {
    setShopHomeUrl(getHomeUrl(useRegion))
    selectRegion(useRegion)
  }, [useRegion])

  return (
    <div className={styles.HeaderShop}>
      <ClientOnly>{showRegionIndicator && <RegionIndicator />}</ClientOnly>
      <div className={styles.HeaderShop__layout}>
        <div className={styles.HeaderShop__burger}>
          <BurgerIcon color="#72035D" />
        </div>

        <div className={styles.HeaderShop__logo}>
          <ClientOnly>
            <Logo href={shopHomeUrl} color="#72035D" uid="header-shop-logo" />
          </ClientOnly>
        </div>

        <div className={styles.HeaderShop__menu}>
          <DesktopMenu theme={HeaderThemes.ShopPurple} />
        </div>
        <ClientOnly>
          {isMobile ? (
            <></>
          ) : (
            <div className={styles.HeaderShop__regionPicker}>
              <RegionPicker />
            </div>
          )}
        </ClientOnly>

        <div className={styles.HeaderShop__cart}>
          <ShopifyCartIcon products={products} />
        </div>
      </div>
    </div>
  )
}
