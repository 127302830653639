import { LocalTime, localTime } from '@naturalcycles/js-lib'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import { languageContext } from '@src/context/language.context'
import { getSignupURL } from '@src/helpers/signupUrl'
import { eurCountries } from '@src/shop/store/shopify.slice'
import { GeolocationCountry, selectGeolocation } from '@src/store/geolocation/geolocation.slice'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from './useAppSelector'

export function useLastChanceBanner(): UseLastChanceBannerResult {
  const [now, setNow] = useState(localTime.now())
  const { locale } = useContext(languageContext)
  const { country } = useAppSelector(selectGeolocation)

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(localTime.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const defaultSaving = '20'
  const defaultEurSavings = '10'

  /**
   * https://docs.google.com/spreadsheets/d/1lwsd2GaXX7fcpny3ROWeYNGqLkdjaZPlEypxo-vwJt4/edit?gid=1531815511#gid=1531815511
   */
  const savingsByCountry: Partial<Record<GeolocationCountry, string>> = {
    [GeolocationCountry.US]: '20',
    [GeolocationCountry.SE]: '24',
    [GeolocationCountry.GB]: '22',
    [GeolocationCountry.DE]: '25',
    [GeolocationCountry.DK]: '20',
    [GeolocationCountry.NO]: '33',
    [GeolocationCountry.FR]: '10',
    [GeolocationCountry.ES]: '22',
    [GeolocationCountry.IE]: '31',
    [GeolocationCountry.CA]: '29',
    [GeolocationCountry.BR]: '14',
    [GeolocationCountry.AU]: '33',
    [GeolocationCountry.CH]: '20',
    [GeolocationCountry.SG]: '8',
    [GeolocationCountry.NL]: '25',
  }

  const [savings, setSavings] = useState(defaultSaving)
  useEffect(() => {
    if (savingsByCountry[country]) {
      setSavings(savingsByCountry[country])
    } else if (eurCountries.includes(country)) {
      setSavings(defaultEurSavings)
    }
  }, [country])

  const isDuringLastChance = now.isBefore(localTime.parse('2025-04-01T23:59:59'))

  const signupUrl = getSignupURL(`${locale}/`).toString()

  const copyEnUS: CopyEntry = useMemo(
    () => ({
      /* eslint-disable no-irregular-whitespace */
      heading: `Don't miss out! ⏳ Save ${savings}% before prices rise. Lock in your best deal today.`,
      headingSmall: `Don't miss out! ⏳ Save ${savings}% before prices rise. <a href='${signupUrl}'>Lock in your best deal today.</a>`,
      buttonText: 'Get started',
    }),
    [savings],
  )

  const copyEsUS: CopyEntry = useMemo(
    () => ({
      heading: `¡No te lo pierdas! ⏳ Ahorra un ${savings}% antes de que suban los precios. ¡Aprovecha la mejor oferta hoy!`,
      headingSmall: `¡No te lo pierdas! ⏳ Ahorra un ${savings}% antes de que suban los precios. <a href="${signupUrl}">Aprovecha la mejor oferta hoy!</a>`,
      buttonText: '¡Suscríbete ya!',
    }),
    [savings],
  )

  const copyPtBR: CopyEntry = useMemo(
    () => ({
      heading: `Não perca essa chance! ⏳ Poupe ${savings}% antes que os preços subam. Garanta o melhor preço hoje.`,
      headingSmall: `Não perca essa chance! ⏳ Poupe ${savings}% antes que os preços subam. <a href="${signupUrl}">Garanta o melhor preço hoje.</a>`,
      buttonText: 'Comece agora',
    }),
    [savings],
  )

  const copySvSE: CopyEntry = useMemo(
    () => ({
      heading: `Missa inte! ⏳ Spara ${savings} % innan vi höjer priset. Säkra ditt bästa erbjudande idag.`,
      headingSmall: `Missa inte! ⏳ Spara ${savings} % innan vi höjer priset. <a href="${signupUrl}">Säkra ditt bästa erbjudande idag.</a>`,
      buttonText: 'Kom igång',
      /* eslint-enable no-irregular-whitespace */
    }),
    [savings],
  )

  const copyMap: Record<SupportedLocale, CopyEntry> = useMemo(
    () => ({
      [SupportedLocale.enUS]: copyEnUS,
      [SupportedLocale.enCA]: copyEnUS,
      [SupportedLocale.esUS]: copyEsUS,
      [SupportedLocale.ptBR]: copyPtBR,
      [SupportedLocale.svSE]: copySvSE,
    }),
    [copyEnUS, copyEsUS, copyPtBR, copySvSE],
  )

  return useMemo(
    () => ({
      isDuringLastChance,
      heading: copyMap[locale].heading,
      headingSmall: copyMap[locale].headingSmall,
      expiration: localTime.parse('2025-04-01T23:59:59'),
      button: {
        url: signupUrl,
        text: copyMap[locale].buttonText,
      },
    }),
    [isDuringLastChance, locale, signupUrl, copyMap],
  )
}

interface CopyEntry {
  heading: string
  headingSmall: string
  buttonText: string
}

interface UseLastChanceBannerResult {
  isDuringLastChance: boolean
  heading: string
  headingSmall: string
  expiration: LocalTime
  button: {
    url: string
    text: string
  }
}
