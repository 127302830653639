import { _sum, _sumBy } from '@naturalcycles/js-lib'
import { useLocation } from '@reach/router'
import { Button } from '@src/components/button/Button.component'
import { ncNavigate } from '@src/helpers/nc-navigate'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { ShippingLocation } from '@src/shop/cnst/shopify.cnst'
import {
  ProductOptions,
  ProductOptionSource,
} from '@src/shop/components/product-options/ProductOptions.component'
import { shopifyService } from '@src/shop/srv/shopify.service'
import { ShopifyProduct } from '@src/shop/store/shopify.model'
import {
  changeLineItemQuantity,
  removeFromCart,
  selectMiniCartOpened,
  selectShopifyShippingLocation,
  setMiniCartOpened,
} from '@src/shop/store/shopify.slice'
import type { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'
import { FeaturedProductsMiniCart } from '../featured-products/FeaturedProductsMiniCart.component'
import { ProductQuantityControl } from '../product-quantity-control/ProductQuantityControl.component'
import styles from './Minicart.module.scss'

interface MiniCartProps {
  openCart: boolean
  setOpenCart: Dispatch<SetStateAction<boolean>>
  products: ShopifyProduct[]
}

export function MiniCart({ openCart, setOpenCart, products }: MiniCartProps): ReactNode {
  const dispatch = useAppDispatch()
  const cart = useAppSelector(state => state.shopify.cart)
  const miniCartOpened = useAppSelector(selectMiniCartOpened)
  const cartSize = _sumBy(Object.values(cart), c => c.quantity)
  const location = useLocation()
  const _isMobile = useBreakpoint().breakpoint < Breakpoints.bpMedium

  const currentCurrency = useAppSelector(state => state.shopify.currency)
  const [currencyCode, setCurrencyCode] = useState('USD')
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const cartPath = `/shop/cart${window.location.search}`
  const isSwedenShipping = shippingLocation === ShippingLocation.SE
  const ovulationTestHref =
    '/shop/' +
    (isSwedenShipping
      ? 'agglossningstest-fran-natural-cycles'
      : 'digital-ovulation-test-refill-pack') +
    window.location.search

  const isFirstRender = useRef(true)

  const freeShippingLimit = currencyCode === 'SEK' ? 400 : currencyCode === 'GBP' ? 30 : 40

  // Step 1: Extract the variant IDs from the cart object keys
  const cartVariantIds = Object.keys(cart)

  // Step 2: Log each product's node.id and compare with cartVariantIds
  const productsNotInCart = products.filter(product => {
    const productNodeId = product.id

    // Check if the product's node.id is in the cart's variant IDs
    const isInCart = cartVariantIds.includes(productNodeId)

    return !isInCart
  })

  // Step 3: Extract the titles of up to 3 products not in the cart
  const productsInCartArray = productsNotInCart.slice(0, 6).map(product => product.title)

  function toggleMiniCart(e: MouseEvent<Element>, bypass = false, link = ''): void {
    setOpenCart(false)

    if (!bypass) {
      e.preventDefault()
    } else {
      if (link) {
        ncNavigate(link)
      }
    }
  }

  function calculatePrice(): number {
    return _sum(
      Object.values(cart).map(lineItem => {
        const variantId = lineItem.variantId
        const product = products.find(p => p.variants.some(v => v.id === variantId))

        if (product) {
          const priceObj = shopifyService.findPrice(
            product,
            currencyCode,
            undefined,
            lineItem.sellingPlanId,
          )

          return Number.parseFloat(priceObj.amount) * lineItem.quantity
        }

        return 0
      }),
    )
  }

  function handleRemove(id: string, title: string): void {
    dispatch(removeFromCart(id, title))
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (cartSize === 1 && !miniCartOpened) {
      dispatch(setMiniCartOpened(true))
      setOpenCart(true)
    }

    if (cartSize === 0 && miniCartOpened) {
      dispatch(setMiniCartOpened(false))
    }
  }, [cartSize, setOpenCart, location.pathname])

  useEffect(() => {
    setCurrencyCode(currentCurrency)
  }, [currentCurrency])

  return (
    <div
      className={openCart ? `${styles['MiniCart']} ${styles['MiniCartIsOpen']}` : styles.MiniCart}
    >
      <div className={styles.MiniCart__sneeze} onClick={e => toggleMiniCart(e)} />
      <div className={styles.MiniCart__scrollContainer}>
        <div className={styles.MiniCart__container}>
          <div className={styles.MiniCart__cartHeading}>
            Your Cart {Object.keys(cart).length ? `(${cartSize})` : ''}
          </div>

          <button onClick={toggleMiniCart} className={styles.MiniCart__button}>
            <span>close mini cart</span>+
          </button>

          <div className={styles.MiniCart__empty}>
            {!Object.keys(cart).length && (
              <div className={styles.MiniCart__cartEmpty}>
                Cart is empty, did you want to buy some LH-tests?{' '}
                <a href={ovulationTestHref} aria-label="Link to ovulation tests">
                  {' '}
                  Find them here
                </a>
                .
              </div>
            )}
          </div>
          {Object.keys(cart).length > 0 && (
            <>
              <div className={styles.MiniCart__productsContainer}>
                {Object.values(cart).map((lineItem, _index) => {
                  const variantId = lineItem.variantId
                  const product = products.find(p => p.variants.some(v => v.id === variantId))

                  if (!product) {
                    return <div key={'pnf'}>Product not found</div>
                  }

                  function handleChangeQuantity(quantity: number): void {
                    if (!product) {
                      return
                    }
                    dispatch(changeLineItemQuantity(lineItem.variantId, quantity, product.title))
                  }

                  const price = shopifyService.findPrice(product, currencyCode)

                  const discountedPrice = shopifyService.applySellingPlanDiscount(
                    product,
                    price.amount,
                    lineItem.sellingPlanId,
                  )

                  return (
                    <div key={product.title} className={styles.MiniCart__productCard}>
                      <img
                        className={styles.MiniCart__productImage}
                        src={product.images[0]?.originalSrc || ''}
                        alt={product.title}
                      />
                      <div className={styles.MiniCart__productBrandLogo}>
                        {product.brandLogo && <img src={product.brandLogo} alt="Product" />}
                      </div>
                      <div className={styles.MiniCart__productTitle}>{product.title}</div>
                      {product.packageQuantity && (
                        <div className={styles.MiniCart__productQtyAmt}>
                          {product.packageQuantity}
                        </div>
                      )}
                      <div className={styles.MiniCart__productPrice}>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: price.currencyCode,
                        }).format(Number.parseFloat(discountedPrice))}
                      </div>
                      <div className={styles.MiniCart__productQuantityControl}>
                        <ProductQuantityControl
                          quantity={lineItem.quantity}
                          minimum={0}
                          maximum={9}
                          onChange={handleChangeQuantity}
                          productName={product.title}
                        />
                      </div>
                      <div className={styles.MiniCart__productRemoveItems}>
                        <button
                          onClick={() => handleRemove(product.variants[0]!.id, product.title)}
                          aria-label={`Remove all items of product ${product.title}`}
                        >
                          Remove
                        </button>
                      </div>
                      {!!product.sellingPlans?.length && (
                        <div className={styles.MiniCart__productOptions}>
                          <ProductOptions
                            product={product}
                            source={ProductOptionSource.MiniCart}
                            price={price}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
                {_isMobile && (
                  <div className={styles.MiniCart__featuredProducts}>
                    <FeaturedProductsMiniCart
                      customHeadline="Featured Products"
                      currentProducts={productsInCartArray}
                      products={products}
                    />
                  </div>
                )}
              </div>
              {!_isMobile && (
                <div className={styles.MiniCart__featuredProducts}>
                  <FeaturedProductsMiniCart
                    customHeadline="Featured Products"
                    currentProducts={productsInCartArray}
                    products={products}
                  />
                </div>
              )}
            </>
          )}
          <div className={styles.MiniCart__spacer} />
          {Object.keys(cart).length > 0 && (
            <div className={styles.MiniCart__checkoutContainer}>
              <div className={styles.MiniCart__totalHeading}>Estimated Total:</div>
              <div className={styles.MiniCart__totalDisclaimer}>
                {' '}
                {calculatePrice() < freeShippingLimit
                  ? '(Excludes delivery charges)'
                  : 'Includes free shipping'}
              </div>
              <div className={styles.MiniCart__totalPrice}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: currencyCode,
                }).format(calculatePrice())}
              </div>
              <div className={styles.MiniCart__checkoutButton}>
                <Button onClick={e => toggleMiniCart(e, true, cartPath)} theme={'brandPurple'} shop>
                  Continue to Checkout
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
