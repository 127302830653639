import { useLocation } from '@reach/router'

export enum PageType {
  Campaign = 'Campaign',
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  Unknown = 'Unknown',
  WebView = 'WebView',
}

export const ConsumerPageTypes = [PageType.StandardHome, PageType.Standard]

export const ShopPageTypes = [PageType.Shop, PageType.ShopHome]

const patternMap = {
  [PageType.Campaign]: [
    /^\/c\/.+/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)refer-a-friend\/?$/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)join-me\/?$/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)join-me-plan\/?$/,
    /^\/samsung\/?$/,
  ],
  [PageType.StandardHome]: [/^\/$/, /^\/es\/?$/, /^\/pt-br\/?$/, /^\/sv\/?$/, /^\/ca\/?$/],

  // Adding this to standard pages otherwise it is interpretted as a press page
  [PageType.Standard]: [/^\/newsletter-signup/],
  [PageType.ShopHome]: [
    /^\/shop\/row?$/,
    /^\/shop\/se?$/,
    /^\/shop\/us?$/,
    /^\/shop\/uk?$/,
    /^\/shop\/eu?$/,
  ],
  [PageType.Shop]: [/^\/shop/],
  [PageType.WebView]: [/\/app-legal/],
}

export function usePageType(): PageType {
  const { pathname } = useLocation()

  let pageType =
    (Object.entries(patternMap).find(([_pageType, patterns]) =>
      patterns.some(pattern => pattern.exec(pathname)),
    )?.[0] as PageType) || PageType.Unknown

  if (pageType === PageType.Unknown) {
    pageType = PageType.Standard
  }

  return pageType
}
