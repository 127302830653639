import { _omit } from '@naturalcycles/js-lib'
import { toURL } from '@src/helpers/url'
import { DatoCmsFileField } from 'dato-cms-assets'
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image'
import type { DetailedHTMLProps, ImgHTMLAttributes, ReactNode } from 'react'
import { Image, ImagePropTypes, ResponsiveImageType } from 'react-datocms'

type ImageElProps = ImagePropTypes &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

interface BaseNcImageProps extends Omit<ImageElProps, 'data'> {
  loading?: 'lazy' | 'eager'
  originalId?: string
  // GatsbyImageProps has alt required, but we can't guarantee that it's set in dato
  alt?: string
  url?: string
}

interface GatsbyNcImageProps extends BaseNcImageProps {
  gatsbyImageData: IGatsbyImageData
}

interface DatoNcImageProps extends BaseNcImageProps {
  responsiveImage: ResponsiveImageType
}

export type NcImageProps = GatsbyNcImageProps | DatoNcImageProps

/**
 * A wrapper around GatsbyImage that sets values for the `decoding` and `fetchpriority` props'
 * based on the `loading` prop.
 */
export function NcImage({ loading = 'lazy', ...props }: NcImageProps): ReactNode {
  if (
    props.alt === undefined &&
    !props['aria-hidden'] &&
    process.env['GATSBY_ENV'] !== 'production'
  ) {
    console.warn(
      `NcImage: "alt" prop is empty for ${
        'responsiveImage' in props
          ? props.responsiveImage.src
          : props.gatsbyImageData.images.fallback?.src
      }. If this is a decorative image, pass "aria-hidden" prop instead of "alt".`,
    )
  }

  if ('responsiveImage' in props) {
    const asUrl = toURL(props.responsiveImage.src!)
    const searchParams = asUrl?.searchParams
    // Add auto=format if we don't
    const auto = searchParams?.get('auto')
    if (!auto) {
      searchParams?.set('auto', 'format')
    } else if (auto && !auto.includes('format')) {
      searchParams?.set('auto', `${auto},format`)
    }

    props.responsiveImage.src = asUrl?.toString()

    const imageProps: ImageElProps = {
      ..._omit(props, ['responsiveImage', 'originalId']),
      // We want to warn on empty alt, but pass '' to the image component and dom to treat it as decorative
      alt: props.alt || '',
      data: props.responsiveImage,
      priority: loading === 'eager',
    }

    return <Image {...imageProps} imgStyle={{ objectFit: props.objectFit || 'cover' }} />
  }

  const imageProps: GatsbyImageProps = {
    ..._omit(props, ['gatsbyImageData', 'originalId']),
    decoding: props.decoding || loading === 'eager' ? 'sync' : 'async',
    fetchpriority: props.fetchPriority || loading === 'eager' ? 'high' : 'low',
    loading,
    // Gatsby image will throw on empty alt, we want to warn instead to avoid user facing errors
    alt: props.alt || '',
    image: props.gatsbyImageData,
  }

  return <GatsbyImage {...imageProps} />
}

export function prepareImage(image: DatoCmsFileField): DatoCmsFileField {
  if (image.responsiveImage) {
    const asUrl = toURL(image.responsiveImage.src!)
    const searchParams = asUrl?.searchParams
    const auto = searchParams?.get('auto')
    if (!auto) {
      searchParams?.set('auto', 'format')
    } else if (auto && !auto.includes('format')) {
      searchParams?.set('auto', `${auto},format`)
    }

    image.responsiveImage.src = asUrl?.toString()
    return image
  }

  const mutatedObject = image.gatsbyImageData

  const changeValue = mutatedObject?.images?.fallback?.src?.replace(
    /auto=format/g,
    'auto=format,compress',
  )

  if (changeValue && mutatedObject.images.fallback) {
    mutatedObject.images.fallback.src = changeValue
  }

  return image
}
