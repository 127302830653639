import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { AddToCartButton } from '@src/shop/components/add-to-cart-button/AddToCartButton.component'
import { shopifyService } from '@src/shop/srv/shopify.service'
import { AddToCartSources, ShopifyProduct } from '@src/shop/store/shopify.model'
import { selectShopifyShippingLocation } from '@src/shop/store/shopify.slice'
import { ReactNode, useEffect, useState } from 'react'
import Slider from 'react-slick'
import styles from './FeaturedProductsMiniCart.module.scss'

interface FeaturedProductsProps {
  currentProducts: (string | undefined)[]
  showHeadline?: boolean
  customHeadline?: string
  products: ShopifyProduct[]
}

export function FeaturedProductsMiniCart({
  currentProducts,
  showHeadline = true,
  customHeadline = 'Recommended for You',
  products,
}: FeaturedProductsProps): ReactNode {
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const allProducts = products.filter(
    p =>
      p.handle !== '10-pregnancy-tests' &&
      !currentProducts.includes(p.title) &&
      p.tags.includes(shippingLocation!),
  )

  const mobileSlider = {
    className: styles.FeaturedProducts__productContainer,
    arrows: true,
    dots: false, // Enable dots for navigation
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 2.5, // Default for larger screens
    slidesToScroll: 2, // Default for larger screens
    responsive: [
      {
        breakpoint: 768, // For screens 768px or less
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
          dots: true, // Show dots on mobile
          arrows: false, // Hide arrows for mobile if desired
        },
      },
    ],
  }

  const featuredProducts: ShopifyProduct[] = allProducts.slice(0, 6)

  const currentCurrency = useAppSelector(state => state.shopify.currency)
  const [currencyCode, setCurrencyCode] = useState('USD')

  useEffect(() => {
    setCurrencyCode(currentCurrency)
  }, [shippingLocation])

  const productNodes = featuredProducts.map(product => {
    const { handle, title, images } = product
    return (
      <a href={`/shop/${handle}`} key={title} className={styles.FeaturedProducts__product}>
        <div className={styles.FeaturedProducts__desktopProductContainer}>
          <img alt={product.title} src={images[0]?.originalSrc} loading="eager" />
          {product.brandLogo && (
            <p className={styles.FeaturedProducts__productBrand}>
              <img src={product.brandLogo} alt="logo name" width="100" />
            </p>
          )}
          <p className={styles.FeaturedProducts__productTitle}> {product.title} </p>
          {product.packageQuantity && (
            <p className={styles.FeaturedProducts__productQuantity}> {product.packageQuantity} </p>
          )}
          <div className={styles.FeaturedProducts__productAdd}>
            <AddToCartButton
              product={product}
              quantity={1}
              size="small"
              source={AddToCartSources.FeaturedProduct}
              ariaLabel={`add ${product.title} to cart`}
              price={new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: shopifyService.findPrice(product, currencyCode, handle).currencyCode,
              }).format(Number.parseFloat(shopifyService.findPrice(product, currencyCode).amount))}
            />
          </div>
        </div>
      </a>
    )
  })

  return (
    <div uid="featured-products" className={styles.FeaturedProducts}>
      {showHeadline && <h4 className={styles.FeaturedProducts__heading}>{customHeadline}</h4>}
      <ClientOnly>
        <Slider {...mobileSlider}>{productNodes}</Slider>
      </ClientOnly>
    </div>
  )
}
