export type PersistedSellingPlan = Pick<
  ShopifySealSellingPlan,
  | 'pricing_policy_fixed_adjustment_type'
  | 'pricing_policy_fixed_adjustment_value'
  | 'delivery_interval'
  | 'delivery_interval_count'
  | 'billing_interval'
  | 'billing_interval_count'
  | 'selling_plan_id'
>

export interface ShopifySealSubscriptionRule {
  id: number
  name: string
  option: string
  intervals: any[] // TODO
  intervals_unit: string
  payment_type: 'auto-charge'
  product_variants: ShopifySealProductVariant[]
  selling_plans: ShopifySealSellingPlan[]
}

export interface ShopifySealProductVariant {
  product_id: string
  variant_id: string | null
}

export interface ShopifySealSellingPlan {
  name: string
  option: string
  delivery_interval: ShopifySealSubscriptionDurationUnit
  delivery_interval_count: number
  billing_interval: ShopifySealSubscriptionDurationUnit
  billing_interval_count: number
  pricing_policy_fixed_adjustment_type: ShopifySealSubscriptionDiscountType
  pricing_policy_fixed_adjustment_value: number
  pricing_policy_recurring_adjustment_type: ShopifySealSubscriptionDiscountType
  pricing_policy_recurring_adjustment_value: number
  selling_plan_id: string
  anchor_cutoff: number
}

export enum ShopifySealSubscriptionDurationUnit {
  year = 'year',
  month = 'month',
  week = 'week',
  day = 'day',
}

export enum ShopifySealSubscriptionDiscountType {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface GetSubscriptionRulesResponse {
  page: number
  total_pages: number
  subscription_rules: ShopifySealSubscriptionRule[]
}

export type ShopifySealResponse<T> =
  | {
      success: true
      payload: T
    }
  | {
      success: false
      error: string
    }

export interface ShopifyCart {
  [variantId: string]: ShopifyLineItem
}

export interface ShopifyPrice {
  price: {
    amount: string
    currencyCode: string
  }
}

export interface ShopifyAffiliateOrderLinkMetaField {
  key: 'affiliate_order_link'
  namespace: 'custom'
  value: string
}

export interface ShopifyProductDetails {
  title: string
  content: string
}

export interface ShopifyProduct {
  id: string
  title: string
  handle: string
  tags: string[]
  description: string
  details: ShopifyProductDetails[]
  images: {
    id: string
    originalSrc: string
  }[]
  seo: {
    title: string
    description: string
  }
  hasOutOfStockVariants: boolean
  variants: {
    id: string
    usd: ShopifyPrice
    sek: ShopifyPrice
    gbp: ShopifyPrice
    eur: ShopifyPrice
  }[]
  packageQuantity: string
  brandLogo: string
  shortDescription: string
  sellingPlans?: PersistedSellingPlan[]
  affiliateOrderLink: string | null
}

export interface ShopifyPage {
  id: string
  handle: string
  title: string
  body: string
  bodySummary: string
}

export interface ShopifyPolicy {
  id: string
  slug: string
  title: string
  url: string
  body: string
}

export interface ShopifyLineItem {
  variantId: string
  quantity: number
  sellingPlanId?: string
  isSubscription?: boolean
  attributes?: [{ key: string; value: string }]
}

export enum AddToCartSources {
  Homepage = 'Homepage',
  ProductPage = 'ProductPage',
  BuyNowButton = 'BuyNowButton',
  FeaturedProduct = 'FeaturedProduct',
}

export interface CreateCartInput {
  buyerIdentity: {
    countryCode: string
  }
  lines: {
    merchandiseId: string
    quantity: number
    sellingPlanId: string | null
  }[]
  attributes: {
    key: string
    value: string
  }[]
}

export interface CreateCartData {
  cartCreate: {
    cart: {
      checkoutUrl: string
    }
  }
}

export interface CreateCheckoutInput {
  buyerIdentity: {
    countryCode: string
  }
  lineItems: {
    variantId: string
    quantity: number
  }[]
  customAttributes: {
    key: string
    value: string
  }[]
}

export interface CreateCheckoutData {
  checkoutCreate: {
    checkout: {
      webUrl: string
    }
  }
}
