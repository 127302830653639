import { Experiment } from '@naturalcycles/shared'
import { BannerDiscountCode } from '@src/components/banner-discount-code/BannerDiscountCode.component'
import { HeaderStandard } from '@src/components/header-standard/HeaderStandard.component'
import { MobileMenu } from '@src/components/mobile-menu/MobileMenu.component'
import { MobileMenu as NewMobileMenu } from '@src/components/mobile-menu-v2/MobileMenu.component'
import { qaContext } from '@src/context/qa.context'
import { getLayout } from '@src/helpers/layout'
import { redDotUnlockSequence } from '@src/helpers/redDot'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { useExperimentBucket } from '@src/hooks/useExperimentBucket'
import { useLastChanceBanner } from '@src/hooks/useLastChanceBanner'
import { PageType, usePageType } from '@src/hooks/usePageType'
import { BannerShop } from '@src/shop/components/banner-shop/BannerShop.component'
import { HeaderShop } from '@src/shop/components/header-shop/HeaderShop.component'
import { ShopifyProduct } from '@src/shop/store/shopify.model'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { LastChanceBanner } from '../last-chance-banner/LastChanceBanner.component'
import styles from './Header.module.scss'

export enum HeaderLayouts {
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  ShopHomeLocationIndicator = 'ShopHomeLocationIndicator',
  StandardHomeDiscountBanner = 'StandardHomeDiscountBanner',
  StandardDiscountBanner = 'StandardDiscountBanner',
  ShopLocationIndicator = 'ShopLocationIndicator',
}

export enum HeaderPositions {
  Absolute = 'Absolute',
  Sticky = 'Sticky',
}

export enum HeaderThemes {
  TransparentSupportPurple = 'TransparentSupportPurple',
  SupportPurple = 'SupportPurple',
  Blue = 'Blue',
  White = 'White',
  ShopPurple = 'ShopPurple',
}

const standard = [
  HeaderLayouts.StandardHome,
  HeaderLayouts.Standard,
  HeaderLayouts.StandardHomeDiscountBanner,
  HeaderLayouts.StandardDiscountBanner,
]
const shop = [
  HeaderLayouts.Shop,
  HeaderLayouts.ShopLocationIndicator,
  HeaderLayouts.ShopHome,
  HeaderLayouts.ShopHomeLocationIndicator,
]

export interface HeaderProps {
  // Specifically added for DynamicTemplate, which has banner that are managed through Dato
  canHaveDiscountBanner?: boolean
  // Passed on shop pages
  products?: ShopifyProduct[]
}

export function Header(props: HeaderProps): ReactNode {
  const { canHaveDiscountBanner = true, products = [] } = props
  const mobileMenuStatus = useAppSelector(selectMobileMenuStatus)
  const discountCode = useAppSelector(state => state.discountCode.code)
  const pageType = usePageType()
  const position = HeaderPositions.Sticky
  const [code, setCode] = useState('')
  const { toggleRedDot } = useContext(qaContext)
  const sequenceState = useState(0)

  const { width } = useBreakpoint()
  const isMobile = width < Breakpoints.bpLarge

  // Determine if a discount code is present and if they saved their region
  useEffect(() => {
    setCode(discountCode || sessionStorage.getItem('discountCode') || '')
  }, [discountCode])

  const layout = getLayout(pageType, code || '')
  // Flip this switch if you want the non-sticky header back for the homepage
  // if (pageType === PageTypes.StandardHome && mobileMenuStatus === MobileMenuStatuses.Closed) {
  //   position = HeaderPositions.Absolute
  // }
  const lastChanceBanner = useLastChanceBanner()

  const className = classNames(
    styles.Header,
    styles[`Header__layout${layout}`],
    styles[`Header__position${position}`],
    {
      [styles.Header__hideBanners]: mobileMenuStatus === MobileMenuStatuses.Open,
    },
  )

  const showDiscountBanner = standard.includes(layout) && canHaveDiscountBanner
  const [showLastChanceBanner, setShowLastChanceBanner] = useState(false)

  const { bucket: AB352 } = useExperimentBucket(Experiment.NCWEB_LAST_CHANCE_BANNER)

  useEffect(() => {
    if (standard.includes(layout) && !(showDiscountBanner && code) && AB352) {
      setShowLastChanceBanner(AB352 === 'test')
      mixpanelService.testImpression(Experiment.NCWEB_LAST_CHANCE_BANNER, AB352)
    }
  }, [layout, showDiscountBanner, code, AB352])

  if ([PageType.Campaign, PageType.WebView].includes(pageType)) {
    return <></>
  }

  return (
    <>
      <header
        uid="site-header"
        onTouchStart={e => redDotUnlockSequence(e, sequenceState, toggleRedDot)}
        className={className}
      >
        <Helmet>
          <body data-header-layout={layout} />
        </Helmet>

        {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
          !isMobile && <BannerShop />}

        {standard.includes(layout) && <HeaderStandard layout={layout} />}

        {shop.includes(layout) && <HeaderShop products={products} />}

        {showDiscountBanner && <BannerDiscountCode />}

        {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
          isMobile && <BannerShop />}

        {isMobile && (shop.includes(layout) ? <MobileMenu /> : <NewMobileMenu />)}
        {showLastChanceBanner && <LastChanceBanner {...lastChanceBanner} />}
      </header>
    </>
  )
}
