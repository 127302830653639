import { GeolocationCountry, geolocationDone } from '@src/store/geolocation/geolocation.slice'

class AddShoppersService {
  async init(): Promise<void> {
    // Needs to wait for geolocation to be done first
    const country = await geolocationDone
    // console.log('testing country: ' + country)

    if (country !== GeolocationCountry.US) {
      return
    }
    // They expect the script to be exactly on this
    // position loadScript did not work here
    const t = document.createElement('script')
    t.type = 'text/javascript'
    t.async = true
    t.id = 'AddShoppers'
    t.src = 'https://shop.pe/widget/widget_async.js#622242ae73efc3646959c111'
    document.getElementsByTagName('head')[0]?.append(t)
  }
}

export const addShoppersService = new AddShoppersService()
